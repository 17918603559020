.App-header {
    background-color: #282c34;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    overflow: hidden;
}
.App-info{
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.logo-domain{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.logo{
    width: 15vh;
    height: 15vh;
}
.domain-name{
    font-weight: 500;
    font-size: 1.5em;
    font-family: 'AmarilloUSAF';
    color: aliceblue;
    cursor: pointer;
    margin: 3vh;
}
.writer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    color: #f0f8ff;
}
.links-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    color: #9faecc;
    text-align: center;
    margin-bottom: -10vh;
}