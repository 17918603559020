.Card-grid{
    background-color: #282c34;
    display: inline-grid;
    flex-wrap: wrap;
    justify-content: space-between;
    grid-template-columns: auto auto auto;
    width: 100vw;
    overflow: hidden;
}
.Card-container{
    height: 70vh;
    width: 33.33vw;
}
.Card-front{
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2vw 2vh;
    justify-content: center;
}
.Card-back{
    height: 70vh;
}
.desc{
    font-size: calc(10px + 2vmin);
    color: rgb(255, 255, 255);
    text-decoration-line: underline;
}